const ColorSelectorMobile = (previewer, inputs) => {
  if (!previewer || !inputs || inputs.length < 1) {
    return;
  }

  inputs.forEach(input => {
    input.addEventListener('change', () => {
      preview();
    });
  });
  const preview = () => {
    let input = null;
    inputs.forEach(candidate => {
      if (candidate.checked) {
        input = candidate;
      }
    });
    if (input) {
      previewer.querySelectorAll('.color-preview').forEach(previewerInstance => {
       const primaryColor = input.getAttribute('data-first-color');
       const secondaryColor = input.getAttribute('data-second-color');
       if(secondaryColor.length === 0) {
         previewerInstance.style.background = primaryColor;
       } else {
         previewerInstance.style.background = `linear-gradient(to right, ${primaryColor}, ${primaryColor} 25%, ${secondaryColor} 25%, ${secondaryColor} 50%, ${primaryColor} 50%, ${primaryColor} 75%, ${secondaryColor} 75%, ${secondaryColor})`
       }
      });

      const colorValue = input.getAttribute('data-value');
      const valueLabel = previewer.querySelector('.color-value');
      const valueLabel2 = previewer;
      if (valueLabel !== null) {
        valueLabel.textContent = colorValue;
      }
      if (valueLabel2 !== null) {
        valueLabel2.value = colorValue;
      }
    }
  };
};

export default ColorSelectorMobile;
