const ColorPicker = (previewer, inputs) => {
  if (!previewer || !inputs || inputs.length < 1) {
    return;
  }

  inputs.forEach(input => {
    input.addEventListener('change', () => {
      preview();
    });
  });
  const preview = () => {
    let input = null;
    inputs.forEach(candidate => {
      if (candidate.checked) {
        input = candidate;
      }
    });
    if (input) {
      previewer.querySelectorAll('.material-preview-item').forEach(previewerInstance => {
        const template = previewerInstance.getAttribute('data-uri-template');
        if (!template) {
          return;
        }
        const uri                               = template.replace('__code__', input.getAttribute('data-color-code'));
        previewerInstance.style.backgroundImage = `url('${uri}')`;
      })
    }
  };
};

export default ColorPicker;
