const BaseSelector = (displayer, inputs) => {
  if (!displayer || !inputs || inputs.length < 1) {
    return;
  }

  inputs.forEach(input => {
    input.addEventListener('change', () => {
      recalculate();
    });
  });
  const recalculate = () => {
    let input = null;
    inputs.forEach(candidate => {
      if (candidate.checked) {
        input = candidate;
      }
    });
    if (input) {
      displayer.textContent = input.getAttribute('data-title');
    } else {
      displayer.textContent = "Wybierz mocowanie"; // todo i18n
    }
  };
};

export default BaseSelector;
