/**
 * This file is part of the glatz package.
 *
 * (c) Solvee
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */

const AppConnectSelects = (drivingSelect, connectedSelect, attributeName, reselectToEmpty = false, hideAsWell = true) => {
  let connectedSelectOptions;

  const isExpanded = (element) => {
    return undefined === element.options;
  };

  const filterConnectedChoices = () => {
    const selectedDrivingValue = findSelectedDrivingValue();
    if (null === selectedDrivingValue || undefined === selectedDrivingValue) {
      return;
    }
    const selectedDrivingValueCode = selectedDrivingValue.getAttribute('data-code');
    let shouldReselect = false;
    const connectedValue = findSelectedConnectedValue();
    connectedSelectOptions.forEach(option => {
      const connectedDrivingCodes = option.getAttribute(attributeName).split(';');
      const toggleOn = -1 !== connectedDrivingCodes.indexOf(selectedDrivingValueCode);

      toggleOption(toggleOn, option);
      if (!shouldReselect && !toggleOn && option === connectedValue) {
        shouldReselect = true;
      }
    });
    if (shouldReselect) {
      if (reselectToEmpty) {
        deselectAllOptions(connectedSelectOptions);
      } else {
        let found = false;
        connectedSelectOptions.forEach((option, index) => {
          if (!found && !option.disabled) {
            selectConnectedOption(option, index);
            found = true;
          }
        });
      }
    }
  };

  const findSelectedDrivingValue = () => {
    return findValue(drivingSelect);
  };

  const findSelectedConnectedValue = () => {
    return findValue(connectedSelect);
  };

  const findValue = (input) => {
    if (!isExpanded(input)) {
      return input.options[input.selectedIndex];
    }

    const values = input.querySelectorAll("input:checked");

    return values.length > 0 ? values[0] : null;
  };

  const getConnectedSelectOptions = () => {
    if (isExpanded(connectedSelect)) {
      return connectedSelect.querySelectorAll("input");
    }
    const options = [];
    for (let i = 0; i < connectedSelect.options.length; i++) {
      options.push(connectedSelect.options.item(i));
    }

    return options;
  };

  const toggleOption = (on, option) => {
    let classTarget = option;
    if ('input' === option.nodeName.toLowerCase()) {
      const parent = option.parentElement;
      if (parent && parent.classList.contains('form-check')) {
        classTarget = parent;
      }
    }

    if (!!on) {
      if (hideAsWell) {
        classTarget.classList.remove('d-none');
      }
      option.disabled = false;
    } else {
      if (hideAsWell) {
        classTarget.classList.add('d-none');
      }
      option.disabled = true;
    }
    option.dispatchEvent(new Event("change"));
  };

  const selectConnectedOption = (option, index) => {
    if (isExpanded(connectedSelect)) {
      option.checked = 'checked';
      option.dispatchEvent(new Event("change"));
    } else {
      connectedSelect.selectedIndex = index;
    }
    connectedSelect.dispatchEvent(new Event("change"));
  };

  const deselectAllOptions = (options) => {
    if (isExpanded(connectedSelect)) {
      options.forEach(option => {
        option.checked = null;
        option.dispatchEvent(new Event("change"));
      });
    } else {
      connectedSelect.selectedIndex = -1;
    }
    connectedSelect.dispatchEvent(new Event("change"));
  };

  if (drivingSelect && connectedSelect) {
    connectedSelectOptions = getConnectedSelectOptions();
    drivingSelect.addEventListener('change', filterConnectedChoices);
    filterConnectedChoices();
  }
};

export default AppConnectSelects;
