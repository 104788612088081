import $ from 'jquery';
import AppProductConfigurator from './shop/app-product-configurator';
import FixDropdowns from './shop/fix-dropdowns';
import AppConnectSelects from './shop/app-connect-selects';
import BaseSelector from './shop/app-base-selector';
import ColorPicker from './shop/app-color-picker';
import ColorSelectorMobile from './shop/app-color-selector-mobile';
import CarouselInit from './shop/carousel-init';


// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

// intersection observer polyfill need to be required before rest
require('intersection-observer');

global.$ = global.jQuery = $;

function attachInitialListeners() {
  FixDropdowns();
  CarouselInit();


  const form = document.querySelector('#app_product_configurator');
  if (form) {
    const intersectionObserver = new IntersectionObserver(function (entries) {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      const element = document.getElementById('mobile_preview_container');
      if (null === element || undefined === element) {
        return;
      }
      if (entries[0].intersectionRatio <= 0) {
        element.classList.add('invisible');
        return;
      } else {
        element.classList.remove('invisible');
      }
    });
    // start observing
    intersectionObserver.observe(form);

    AppProductConfigurator(form, document.getElementsByClassName('add-to-cart-button'));
    BaseSelector(
      document.getElementById('app_umbrella_base_selector_value'),
      document.getElementsByName('app_configurator[umbrella_base_type]'),
    );
    ColorPicker(
      document.getElementById('app_configurator_umbrella_color_preview'),
      document.getElementsByName('app_configurator[umbrella_color]'),
    );
    ColorSelectorMobile(
      document.getElementById('app_umbrella_color_selector'),
      document.getElementsByName('app_configurator[umbrella_color]'),
    );
    ColorSelectorMobile(
      document.getElementById('selectedColorInput'),
      document.getElementsByName('app_configurator[umbrella_color]'),
    );
    ColorSelectorMobile(
      document.getElementById('app_umbrella_frame_color_selector'),
      document.getElementsByName('app_configurator[umbrella_frame_color]'),
    );
    // ColorSelectorMobile(
    //   document.getElementById('selectedFrameColorInput'),
    //   document.getElementsByName('app_configurator[umbrella_frame_color]')
    // );
  }

  AppConnectSelects(
    document.getElementById('app_configurator_umbrella_material_category'),
    document.getElementById('app_configurator_umbrella_color'),
    'data-umbrella_material_category',
  );

  AppConnectSelects(
    document.getElementById('app_configurator_umbrella_canopy_shape'),
    document.getElementById('app_configurator_umbrella_canopy_size'),
    'data-umbrella_canopy_shape',
  );

  AppConnectSelects(
    document.getElementById('app_configurator_umbrella_canopy_size'),
    document.getElementById('app_configurator_umbrella_base_selector'),
    'data-umbrella_canopy_size',
    true,
  );

  AppConnectSelects(
    document.getElementById('app_configurator_umbrella_material_category'),
    document.getElementById('app_configurator_umbrella_frame_color'),
    'data-umbrella_material_category',
  );

  AppConnectSelects(
    document.getElementById('app_configurator_pergola_size'),
    document.getElementById('app_configurator_pergola_led_lightning'),
    'data-pergola_size',
    false,
    false,
  );

  AppConnectSelects(
    document.getElementById('app_configurator_furniture_fabric_material'),
    document.getElementById('app_configurator_furniture_fabric_colour'),
    'data-furniture_fabric_material',
  );

  AppConnectSelects(
    document.getElementById('app_configurator_sail_fabric_type'),
    document.getElementById('app_configurator_sail_colour'),
    'data-sail_fabric_type',
  );
}

global.reattachSyliusConfiguratorListeners = function () {
  attachInitialListeners();

  const $selector = $('#app_product_configurator [data-toggle="dropdown"]');
  $selector.each((_, elem) => {
    const $elem = $(elem);

    $elem.click(() => {
      const $parent = $elem.parent(),
        $dropdown = $parent.find('.dropdown-menu');

      $dropdown.toggleClass('show');
      $parent.toggleClass('show');

      $dropdown.on('click', '.form-check', () => {
        $dropdown.removeClass('show');
        $parent.removeClass('show');
      });

      return false;
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  attachInitialListeners();
});

