const configureDropdowns = () => {
  // if ($(window).width() >= 980) {
  //
  //   $('.navbar .dropdown-toggle').hover(function () {
  //     $(this).parent().toggleClass('show');
  //     $(this).parent().find('.dropdown-menu').toggleClass('show');
  //   });
  //
  //   $('.navbar .dropdown-menu').mouseleave(function () {
  //     $(this).removeClass('show');
  //   });
  // } else {
  //
  //   $('.navbar .dropdown-toggle').off('hover');
  //   $('.navbar .dropdown-menu').off('mouseleave');
  //
  // }
};

const FixDropdowns = () => {
  // $('.dropdown-toggle').click(function() {
  //   $(this).parent().find('.dropdown-menu').toggleClass('show');
  //   $(this).parent().toggleClass('show');
  //   return false;
  // });
  //
  // configureDropdowns();
  //
  // $(window).resize(function () {
  //   configureDropdowns();
  // });

  // fix search dropdown behaviour
  const $searchDropdownTrigger = $('#search_dropdown_trigger');
  const $searchDropdownMenu    = $('#search_dropdown_menu');
  const $searchDropdownInput   = $('#search_input');

  $searchDropdownTrigger.on('click', () => {
    $searchDropdownMenu.toggleClass('show');
    $searchDropdownInput.focus();
  });
};

export default FixDropdowns;
