import './owl.carousel.min';

const CarouselInit = () => {
  function startProgressBar() {
    $('.slide-progress').css({
      width: '100%',
      transition: 'width 5000ms',
    });
  }

  function resetProgressBar() {
    $('.slide-progress').css({
      width: 0,
      transition: 'width 0s',
    });
  }

  $('#realizationCarousel .owl-carousel').owlCarousel({
    items: 1,
    loop: ($('.owl-carousel .items').length > 5),
    autoplay: true,
    nav: true,
    navText: ['', ''],
    dots: true,
    onInitialized: startProgressBar,
    onTranslate: resetProgressBar,
    onTranslated: startProgressBar,
  });

  $('#whyGlatzCarouselMobile .owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    nav: true,
    navText: ['', ''],
    dots: true,
    onInitialized: startProgressBar,
    onTranslate: resetProgressBar,
    onTranslated: startProgressBar,
  });

  $('#productsCarousel .owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    nav: false,
    navText: ['', ''],
    dots: true,
    onInitialized: startProgressBar,
    onTranslate: resetProgressBar,
    onTranslated: startProgressBar,
  });

  $('#productGalleryCarousel .owl-carousel').owlCarousel({
    loop: true,
    autoplay: false,
    nav: true,
    navText: ['', ''],
    dots: false,
    responsive: {
      0: {
        items: 2,
      },
      800: {
        items: 3,
      },
    },
  });

  $('#productGalleryCarousel .owl-carousel').on('click', '.owl-item .item img', function () {
    $('#productImage').attr('href', this.dataset.src);
    $('#productImage img').attr('src', this.dataset.src);
  });
};

export default CarouselInit;
