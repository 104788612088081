/**
 * This file is part of the glatz package.
 *
 * (c) Solvee
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */

import axios from 'axios';
import serialize from 'form-serialize';
import GLightbox from 'glightbox';

const glightbox = GLightbox({ selector: 'glightbox' });

function debounce(func, wait, immediate) {
  let timeout;

  return function () {
    const context = this, args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

const AppProductConfigurator = (el, addToCartButtons) => {
  const element           = el;
  const url               = element.getAttribute('action');
  const validationElement = element.querySelector('[data-js-configurator-form="error"]');
  let currentValue        = serialize(element, {empty: true});

  const toggleSubmit = (enable) => {
    if (null === addToCartButtons || undefined === addToCartButtons) {
      return;
    }
    for (const index in addToCartButtons) {
      const button = addToCartButtons.item(index);
      if (enable) {
        delete button.disabled;
      } else {
        button.disabled = 'disabled';
      }
    }
  };

  const hasEmptyFields = (value) => {
    if (null === value || undefined === value || null === value['app_configurator'] || undefined === value['app_configurator']) {
      return true;
    }
    const dataSet = value['app_configurator'];
    for (const field in dataSet) {
      if (!dataSet.hasOwnProperty(field)) {
        continue;
      }
      const fieldValue = dataSet[field];
      if (null === fieldValue || undefined === fieldValue) {
        return true;
      }
      if (fieldValue.trim() === '') {
        return true;
      }
    }

    return false;
  };

  const doSubmit = debounce((e) => {
    const newValue = serialize(element, {empty: true});
    if (JSON.stringify(newValue) === JSON.stringify(currentValue)) {
      return;
    }
    currentValue = newValue;
    if (hasEmptyFields(currentValue)) {
      toggleSubmit(false);

      return;
    }

    validationElement.classList.add('d-none');
    const request = axios.post(url, currentValue);

    if (null !== e) {
      e.preventDefault();
    }

    request.then((response) => {
      validationElement.classList.add('d-none');
      if (window.location.href === response.data.redirect) {
        validationElement.classList.remove('d-none');
        validationElement.innerHTML = 'Obecnie wyświetlony produkt pasuje do podanych kryteriów.'; // todo i18n
        element.classList.remove('loading');
        toggleSubmit(true);

        return;
      }
      // // skip redirect
      axios.get(response.data.redirect).then(newVariant => {
        const el = document.createElement('html');
        el.innerHTML = newVariant.data;

        const newContent = el.getElementsByClassName('main-content')[0];
        const oldContent = document.getElementsByClassName('main-content')[0];
        if (!oldContent || !newContent || !oldContent.parentNode) {
          return;
        }
        oldContent.parentNode.replaceChild(newContent, oldContent);
        window.history.pushState({}, null, response.data.redirect);
        global.reattachSyliusConfiguratorListeners();
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          glightbox.reload();
        }, 100);
      });
    });

    request.catch((error) => {
      validationElement.classList.remove('d-none');
      let validationMessage = '';

      if (Array.isArray(error.response.data) || typeof error.response.data === 'object') {
        Object.entries(error.response.data).forEach(([, message]) => {
          validationMessage += message.errors;
        });
      } else {
        validationMessage += 'Wystąpił niespodziewany błąd.'; // todo i18n
      }

      validationElement.innerHTML = validationMessage;
      element.classList.remove('loading');
    });
  }, 350);

  element.addEventListener('submit', (e) => {
    doSubmit(e);
  });
  element.addEventListener('change', () => {
    doSubmit(null);
  });
  const inputs = element.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs.item(i).addEventListener('change', () => {
      doSubmit(null);
    });
  }
};

export default AppProductConfigurator;
